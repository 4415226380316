import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";
import { SiWhatsapp } from "react-icons/si";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function CareerDescription() {
    const classes = useStyles();
    return (
        <div className={classNames(classes.aboutDescription)}>
            <GridContainer>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>Only on Indian Bangles website We are Hiring</h3>
                    <h5 className={classes.description}>
                    Do you have what it takes to be a winner? If Yes, then we definitely have an opportunity for you to be a part of team {"'"}INSHA{"'"}
                    </h5>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>GRAPHIC Designer</h3>
                    <h4 style={{textTransform: "uppercase"}}>1-2 years experience OR <a href="#">Apply</a></h4>
                    <h5 className={classes.description}>
                    We are looking out for candidates experienced in conceptualizing ideas, planning out and designing ads, brochures, logos and business stationery. Strong knowledge of Photoshop and CorelDraw is essential.
                    </h5>
                    <ul>
                        <li className={classes.description}>Setting layouts, interfaces and color scheme</li>
                        <li className={classes.description}>Ability to create an idea into a visual representation</li>
                        <li className={classes.description}>Developing visuals for new brands and products and maintain the existing ones</li>
                        <li className={classes.description}>Supporting the brand team in meeting all the design requirements and deadlines for various campaigns</li>
                        <li className={classes.description}>Creative flair, originality and a strong visual sense</li>
                        <li className={classes.description}>Maturity of thought and confidence to present and explain ideas to clients</li>
                        <li className={classes.description}>Consistency in producing brand aligned artwork across media (print, outdoor & online)</li>
                    </ul>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>WEB Designer</h3>
                    <h4 style={{textTransform: "uppercase"}}>1-2 years experience OR <a href="#">Apply</a></h4>
                    <h5 className={classes.description}>
                    We are seeking talented, creative, generalists who have had previous experience designing web interfaces with very good understanding of HTML and CSS and jQuery  creating a site maintaining W3C Standards. Creative design is a must! Most importantly, we seek an energetic and passionate designer who works well in a fast-paced environment.
                    </h5>
                    <ul>
                        <li className={classes.description}>Develop visual layout for our clients projects using liquid/responsive design concept</li>
                        <li className={classes.description}>Good grasp of jQuery to achieve and accomplish a Flash-free design</li>
                        <li className={classes.description}>Collaborate with team members to make design decisions</li>
                        <li className={classes.description}>Create web designs, graphics, and templates compatible with most modern web browsers</li>
                        <li className={classes.description}>Knowledge of working with open source with material UI template like HTML, React, Angular, React Native template for ios  and android etc</li>
                    </ul>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>WEB Developer</h3>
                    <h4 style={{textTransform: "uppercase"}}>1-2 years experience OR <a href="#">Apply</a></h4>
                    <h5 className={classes.description}>
                    We are seeking talented, creative, generalists who have had previous experience designing web interfaces with very good understanding of HTML and CSS and jQuery  creating a site maintaining W3C Standards. Creative design is a must! Most importantly, we seek an energetic and passionate designer who works well in a fast-paced environment.
                    </h5>
                    <ul>
                        <li className={classes.description}>Develop visual layout for our clients projects using liquid/responsive design concept</li>
                        <li className={classes.description}>Good grasp of jQuery to achieve and accomplish a Flash-free design</li>
                        <li className={classes.description}>Collaborate with team members to make design decisions</li>
                        <li className={classes.description}>Create web designs, graphics, and templates compatible with most modern web browsers</li>
                        <li className={classes.description}>Knowledge of working with open source with material UI template like HTML, React, Angular, React Native template for ios  and android etc</li>
                    </ul>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>CLIENT Servicing Executives</h3>
                    <h4 style={{textTransform: "uppercase"}}>1-2 years experience OR <a href="#">Apply</a></h4>
                    <h5 className={classes.description}>
                    Must have prior work experience in a creative/designing agency focusing on partnering with clients for their design needs.
                    </h5>
                    <ul>
                        <li className={classes.description}>Must be able to carry out end to end management of client campaigns.</li>
                        <li className={classes.description}>Ensuring client satisfaction is the paramount objective</li>
                        <li className={classes.description}>Understanding campaign objective or requirement and planning the same with the design team</li>
                        <li className={classes.description}>Ensuring customer delight and increasing sales through superior servicing</li>
                        <li className={classes.description}>The role requires coordination skills with the design / tech team, good communication is essential</li>
                    </ul>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{textTransform: "uppercase"}}>SMO Social Media Optimization </h3>
                    <h4 style={{textTransform: "uppercase"}}>0-1 years experience OR <a href="#">Apply</a></h4>
                    <h5 className={classes.description}>
                    Social media accounts familiarity: Facebook, LinkedIn, Twitter, Instagram, Pinterest, Google+
                    </h5>
                    <ul>
                        <li className={classes.description}>Link building and socialization of our client{"'"}s online content</li>
                        <li className={classes.description}>Familiarity with the Internet, social media sites, search engines, discussion boards, blogs and video sharing sites is an asset but a willingness to learn is essential</li>
                        <li className={classes.description}>You must possess excellent written and verbal communication skills in order to write and edit high quality content</li>
                        <li className={classes.description}>A background in interactive marketing or public relations is considered a plus</li>
                        <li className={classes.description}>The ability to find solutions in order to generate measurable results for clients is necessary as well as the generation of reports on those efforts</li>
                    </ul>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto, classes.textCenter)}
                >
                    <h3 className={classes.description}>Need to ask us a question or have a requirement?</h3>
                    <h5 className={classes.description}>Connect with us by clicking the button below and filling out a quick contact form.</h5>
                    <h5 className={classes.description}>We promise to get back to you in the least possible time (within 24 hours).</h5>
                    <h3 className={classes.description}><a
                        href={`https://api.whatsapp.com/send?phone=919967457786&text=https://indianbangles.in/%20Hello%20I%20am%20Interested&app_absent=0`}
                    ><SiWhatsapp color="green" /> Click on the WhatsApp icon</a> to get connected with us. Consultation is FREE</h3>
                </GridItem>
            </GridContainer>
            <br />
        </div>
    );
}