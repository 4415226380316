import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";
import { SiWhatsapp } from "react-icons/si";
import { MdEmail } from "react-icons/md";
import { ImMobile } from "react-icons/im";
import { Link } from "@material-ui/core";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function ShippingDescription() {
    const classes = useStyles();

    const activeSession = sessionStorage.getItem("sessionData");
    const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

    return (
        <div className={classNames(classes.aboutDescription)}>
            <GridContainer>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{ textTransform: "uppercase" }}>Delivery</h3>
                    <h4 style={{ textTransform: "uppercase" }}>
                        DELIVERY AT INSHA
                    </h4>
                    <h5 className={classes.description}>
                        The order will be delivered within 25 working days in India from the date of payment and it will take a minimum 90 working days to be delivered outside India.
                        {/* {activeSession ?
                            <a href="/return">here</a> : <a href="/bangles-login">here</a>}. */}
                    </h5>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{ textTransform: "uppercase" }}>
                        What are the delivery charges?
                    </h3>
                    <ul>
                        <li className={classes.description}>
                            Delivery charge varies with each Seller.
                        </li>
                        <li className={classes.description}>Sellers incur relatively higher shipping costs on low value items. In such cases, charging a nominal delivery charge helps them offset logistics costs. Please check your order summary to understand the delivery charges for individual products.</li>

                    </ul>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{ textTransform: "uppercase" }}>
                        Does Bangles.org.in deliver internationally?
                    </h3>
                    <ul>
                        <li className={classes.description}>
                            As of now, Bangles.org.in deliver items internationally.
                        </li>
                    </ul>
                </GridItem>

                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{ textTransform: "uppercase" }}>
                        WHAT ARE THE GUIDELINES TO ACCEPT SHIPMENTS?
                    </h3>
                    <ul>
                        <li className={classes.description}>
                            Before accepting shipment of any product, please ensure that the
                            product{"'"}s packaging is not damaged or tampered with.
                        </li>
                        <li className={classes.description}>
                            In case the package is damaged or tampered with, you are requested
                            to refuse to accept delivery. We will assure you a refund upon
                            such refused delivery, provided the order is prepaid.
                        </li>
                    </ul>
                </GridItem>
                <GridItem
                    md={12}
                    sm={12}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                >
                    <h3 style={{ textTransform: "uppercase" }}>ANY OTHER HELP?</h3>
                    <h5 className={classes.description}>
                        If you need help with something that is not mentioned above you can
                        contact us in the following way:
                        <br />
                        Phone:{" "}
                        <a href="tel:+91-9967457786">
                            <SiWhatsapp color="green" /> 9967457786
                        </a>
                        /
                        <a href="tel:+91-2247501787">
                            <ImMobile /> 2247501787
                        </a>
                        <br />
                        Email:{" "}
                        <Link
                            to="javascript:void(0)"
                            onClick={() => (window.location = "mailto:info@indianbangles.in")}
                        >
                            <span>
                                <MdEmail /> info@indianbangles.in
                            </span>
                        </Link>
                        <br />
                    </h5>
                </GridItem>
            </GridContainer>
            <br />
        </div>
    );
}
