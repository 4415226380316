export const headApi = `https://api.bangles.org.in/api/rest`;
// export const localApi = `http://localhost:8085/api`;
// export const localApi = `https://inshabangles.com:8443/api`;
// export const localApi = `https://bangles.org.in:8443/api`;
// export const localApi = `https://api.inshabangles.com/api`;
export const localApi = `https://api.inshabangles.com/inshaApp`;

export const username = "admin";
export const password = "Insha_!@#$21Bangles";

export const credentials = btoa(`${username}:${password}`);