/*eslint-disable*/
import React, { useEffect } from "react";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { headApi } from "utils/headApi";
import { localApi, credentials } from "utils/headApi";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Apps from "@material-ui/icons/Apps";
import { FaUserEdit, FaExpeditedssl, FaAddressBook } from "react-icons/fa";
import { IoFingerPrintSharp } from "react-icons/io5"; //IoBagAddOutline
import { IoMdLogOut } from "react-icons/io";
import { RiShoppingBagLine } from "react-icons/ri";
import { MdAddchart } from "react-icons/md";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { FiPhoneCall } from "react-icons/fi";
import Close from "@material-ui/icons/Close";
// import InputAdornment from "@material-ui/core/InputAdornment";
import Slide from "@material-ui/core/Slide";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import EmailIcon from "@material-ui/icons/Email";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import CardFooter from "components/Card/CardFooter.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";

import styles from "assets/jss/material-kit-pro-react/components/inshaheaderLinkStyle.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";
// import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

import { cardTitle } from "assets/jss/material-kit-pro-react.js";

const style = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

const useStyles = makeStyles(styles);
const useStyle = makeStyles(javascriptStyles);
const useStyl = makeStyles(headersStyle);
const useSty = makeStyles(style);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function InshaHeaderLink(props) {
  const [bangle, setbangle] = React.useState([]);
  const [earing, setEaring] = React.useState([]);
  const [ring, setRing] = React.useState([]);
  const [watch, setWatch] = React.useState([]);
  const [kada, setKada] = React.useState([]);
  const [loginModal, setLoginModal] = React.useState(false);
  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [otpNumber, setOtpNumber] = React.useState("");
  const [otpNumberErr, setOtpNumberErr] = React.useState("");
  const [otpScreen, setOtpScreen] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [cardList, setCardList] = React.useState([]);

  const [reSend, setReSend] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailErr, setEmailErr] = React.useState("");
  const [admin, setAdmin] = React.useState("");

  // const easeInOutQuad = (t, b, c, d) => {
  //   t /= d / 2;
  //   if (t < 1) return (c / 2) * t * t + b;
  //   t--;
  //   return (-c / 2) * (t * (t - 2) - 1) + b;
  // };

  // const smoothScroll = (e, target) => {
  //   if (window.location.pathname === "/sections") {
  //     var isMobile = navigator.userAgent.match(
  //       /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  //     );
  //     if (isMobile) {
  //       // if we are on mobile device the scroll into view will be managed by the browser
  //     } else {
  //       e.preventDefault();
  //       var targetScroll = document.getElementById(target);
  //       scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
  //     }
  //   }

  // };
  // const scrollGo = (element, to, duration) => {
  //   var start = element.scrollTop,
  //     change = to - start,
  //     currentTime = 0,
  //     increment = 20;

  //   var animateScroll = function () {
  //     currentTime += increment;
  //     var val = easeInOutQuad(currentTime, start, change, duration);
  //     element.scrollTop = val;
  //     if (currentTime < duration) {
  //       setTimeout(animateScroll, increment);
  //     }
  //   };
  //   animateScroll();
  // };

  const { dropdownHoverColor } = props;
  const classes = useStyles();
  const classPop = useStyle();
  const classHeader = useStyl();
  const history = useHistory();
  const classe = useStyl();

  // const date = Intl.DateTimeFormat("fr-CA", {
  //   year: "numeric",
  //   month: "numeric",
  //   day: "numeric",
  // })
  //   .format(new Date())
  //   .replaceAll("/", "-");

  const activeSession = sessionStorage.getItem("sessionData");
  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  // useEffect(() => {
  //   const loginName = "user";
  //   const loginPassword = "96f49c85-ff8b-4178-b5ff-66d64a71c8c6";

  //   const fetchTypeData = async () => {
  //     try {
  //       const response = await fetch(localApi + `/type/list`, {
  //         // mode: "no-cors", // no-cors, *cors, same-origin
  //         headers: new Headers({
  //           Authorization: `Basic ${base64_encode(
  //             `${loginName}:${loginPassword}`
  //           )}`,
  //         }),
  //       });
  //       const json = await response.json();
  //       console.log(json.TypeList);
  //     } catch (error) {
  //       console.log("error", error);
  //     }
  //   };

  //   fetchTypeData();
  // }, []);

  useEffect(() => {

    const fetchAdminData = async () => {
      try {
        const response = await fetch(localApi + `/admin/allList`, {
          method: "GET",
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        });
        const json = await response.json();
        setAdmin(json.AdminList);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchAdminData();
  }, []);

  useEffect(() => {
    const fetchCartListData = async () => {
      try {
        const response = await fetch(localApi + `/clientGroup/allList`, {
          method: "GET",
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        });
        const json = await response.json();
        const cList = json.ClientList.filter((f) => f.regId === userObj.id)[0];

        const list =
          localApi +
          `/cart/expireCart/byAdminAndClientIdAndAcitve/${userObj.admin.id}/${cList.id}?active=0`;

        const result = await fetch(list, {
          method: "GET",
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        });
        const data = await result.json();
        setCardList(data);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchCartListData();
  }, []);

  useEffect(() => {
    const fetchBanglesData = async () => {
      try {
        const response = await fetch(
          localApi + `/parent/list/byCategoryId/11`, {
          method: "GET",
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        }
        );
        const json = await response.json();
        setbangle(json);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchBanglesData();
  }, []);

  useEffect(() => {
    const fetchEarRingsData = async () => {
      try {
        const response = await fetch(
          localApi + `/parent/list/byCategoryId/12`, {
          method: "GET",
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        }
        );
        const json = await response.json();
        setEaring(json);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchEarRingsData();
  }, []);

  useEffect(() => {
    const fetchFingerRingsData = async () => {
      try {
        const response = await fetch(
          localApi + `/parent/list/byCategoryId/13`, {
          method: "GET",
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        }
        );
        const json = await response.json();
        setRing(json);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchFingerRingsData();
  }, []);

  useEffect(() => {
    const fetchWatchData = async () => {
      try {
        const response = await fetch(
          localApi + `/parent/list/byCategoryId/14`, {
          method: "GET",
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        }
        );
        const json = await response.json();
        setWatch(json);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchWatchData();
  }, []);

  useEffect(() => {
    const fetchKadaData = async () => {
      try {
        const response = await fetch(
          localApi + `/parent/list/byCategoryId/15`, {
          method: "GET",
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        }
        );
        const json = await response.json();
        setKada(json);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchKadaData();
  }, []);

  const handleMobileNumber = (mobile) => {
    setMobile(mobile);
    // setMobileErr("");
    fetch(localApi + `/register/find/byMobile/${mobile}`, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          // setMobileErr(result.message);
          if (result.message === "Mobile No. Already Registered") {
            setMobileErr("");
          } else if (result.message === "Not Found") {
            setMobileErr("Your No. Not Registered");
          } else {
            setMobileErr(result.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
    fetch(localApi + `/register/find/byEmail/${value}`, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // setEmailErr(result.message);
          if (result.message === "Email ID Already Registered") {
            setEmailErr("");
          } else if (result.message === "Not Found") {
            setEmailErr("Your Email Not Registered");
          } else {
            setEmailErr(result.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleOtp = (e) => {
    // console.log(e.target.value);
    setOtpNumber(e.target.value);
    setOtpNumberErr("");
  };

  const handleSendOtpWithMobile = async () => {
    // console.log("okkkk");
    const getFindAdmin = admin.find((f) => f.websitename === "inshabangles.com");
    const api = localApi + `/register/byMobile/${mobile}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    let errorCount = 0;
    if (mobile === "" || !mobile) {
      errorCount++;
      setMobileErr("Mobile Number is Invalid");
    }
    if (errorCount === 0) {
      fetch(
        localApi +
        `/otp/send/byMobile?mobile=${mobile}&templateID=1207161762762132153`,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Basic ${credentials}`,
          },
          body: JSON.stringify({
            websitename: "indianbangles.in",
            followId: getFindAdmin.id,
            adminId: getResult.aId,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          // console.log(response);
          setMobileErr(response.message);
          if (response && response.error === true) {
            setOtpScreen({ otpScreen: true });
            setOtp(response.message);
          }
          // "Sender Not Found to +919967457786"
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleSendOtpWithEmail = async () => {
    const getFindAdmin = admin.find((f) => f.websitename === "inshabangles.com");
    const api = localApi + `/register/byEmail/${email}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Basic ${credentials}`,
      },
      body: JSON.stringify({
        to: email,
        subject: "API CREATION SUBJECT",
        websitename: "indianbangles.in",
        followId: getFindAdmin.id,
        adminId: getResult.aId,
      }),
    };
    fetch(
      localApi + `/otp/send/byEmail?e=noreply@inshabangles.com`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        setEmailErr(response.message);
        if (response && response.error === true) {
          setOtpScreen({ otpScreen: true });
          setOtp(response.message);
        }
        // "Sender Not Found to +919967457786"
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoginWithMobile = () => {
    let errorCount = 0;
    if (otpNumber === "" || !otpNumber) {
      errorCount++;
      setOtpNumberErr("Enter Otp Number");
    }
    if (errorCount === 0) {
      fetch(
        localApi +
        `/register/recieved/byMobile?mobile=${mobile}&otp=${otpNumber}`,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Basic ${credentials}`,
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          // console.log(response);
          setOtpNumberErr(response.message);
          if (response.errorDetails.error === true) {
            const userObj = response.registerSession;
            localStorage.setItem("userObj", JSON.stringify(userObj));
            sessionStorage.setItem("sessionData", JSON.stringify(userObj));
            history.push("/product-list");
          }
          // if (response.apiResponse.error !== "false") return false;
          // const userObj = response.user;
          // localStorage.setItem("userObj", JSON.stringify(userObj));
          // sessionStorage.setItem("sessionData", JSON.stringify(userObj));
          // history.push("/admin/dashboard");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleLoginWithEmail = () => {
    let errorCount = 0;
    if (otpNumber === "" || !otpNumber) {
      errorCount++;
      setOtpNumberErr("Enter Otp Number");
    }
    if (errorCount === 0) {
      fetch(
        localApi + `/register/recieved/byEmail?email=${email}&otp=${otpNumber}`,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Basic ${credentials}`,
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          // console.log(response);
          setOtpNumberErr(response.message);
          if (response.errorDetails.error === true) {
            const userObj = response.registerSession;
            localStorage.setItem("userObj", JSON.stringify(userObj));
            sessionStorage.setItem("sessionData", JSON.stringify(userObj));
            history.push("/product-list");
          }
          // if (response.apiResponse.error !== "false") return false;
          // const userObj = response.user;
          // localStorage.setItem("userObj", JSON.stringify(userObj));
          // sessionStorage.setItem("sessionData", JSON.stringify(userObj));
          // history.push("/admin/dashboard");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleLogOut = () => {
    // const finalURL = localApi + `/otp/logout/registerById/${userObj && userObj.id}`;
    fetch(localApi + `/otp/logout/registerById/${userObj && userObj.id}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Basic ${credentials}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        if (response.error === true) {
          localStorage.removeItem("sessionData");
          sessionStorage.removeItem("sessionData");
          history.push("/");
          window.location.reload(false);
        }
      });
  };

  return (
    <div className={classHeader.collapse}>
      <List className={classes.list + " " + classes.mlAuto}>
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText="Bangles"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList={
              bangle &&
              bangle.map((x) => (
                <Link
                  to={`/size-mm-list?catdesc=BANGLES&cat1desc=${x.title}&Plating=Gold%20Plating`}
                  className={classes.dropdownLink}
                  key={x.id}
                >
                  {x.title}
                </Link>
              ))
            }
          />
        </ListItem>

        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText="Earrings"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList={
              earing &&
              earing.map((x) => (
                <Link
                  to={`/size-mm-list?catdesc=EARRING&cat1desc=${x.title}&Plating=Gold%20Plating`}
                  className={classes.dropdownLink}
                  key={x.id}
                >
                  {x.title}
                </Link>
              ))
            }
          />
        </ListItem>

        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText="Finger Ring"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList={
              ring &&
              ring.map((x) => (
                <Link
                  to={`/size-mm-list?catdesc=FINGER RINGS&cat1desc=${x.title}&Plating=Gold%20Plating`}
                  className={classes.dropdownLink}
                  key={x.id}
                >
                  {x.title}
                </Link>
              ))
            }
          />
        </ListItem>

        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText="Watch"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList={
              watch &&
              watch.map((x) => (
                <Link
                  to={`/size-mm-list?catdesc=WATCH&cat1desc=${x.title}&Plating=Gold%20Plating`}
                  className={classes.dropdownLink}
                  key={x.id}
                >
                  {x.title}
                </Link>
              ))
            }
          />
        </ListItem>

        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText="Punjabi kada"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList={
              kada &&
              kada.map((x) => (
                <Link
                  to={`/size-mm-list?catdesc=PUNJABI KADA&cat1desc=${x.title}&Plating=Gold%20Plating`}
                  className={classes.dropdownLink}
                  key={x.id}
                >
                  {x.title}
                </Link>
              ))
            }
          />
        </ListItem>
        {/* <ListItem className={classes.listItem}>
          <Button
            href="/todays-deal"
            className={classes.navLink}
            // onClick={(e) => e.preventDefault()}
            color="transparent"
          >
            Todays Deals
          </Button>
        </ListItem> */}
        {!activeSession ? (
          ""
        ) : (
          <span>
            {cardList && cardList.length != 0 ? (
              <ListItem className={classes.listItem}>
                <CustomDropdown
                  noLiPadding
                  navDropdown
                  hoverColor={dropdownHoverColor}
                  buttonText="Cart Count"
                  buttonProps={{
                    className: classes.navLink,
                    color: "transparent",
                  }}
                  buttonIcon={ShoppingCartIcon}
                  dropdownList={[
                    <Link
                      key="cart"
                      to={`/add-cart`}
                      // to={`/cart?byuserid=919967457786&clientid=${
                      //   userObj && userObj.mobile.slice(1)
                      // }&expdate=${date}`}
                      className={classes.dropdownLink}
                    >
                      <MdAddchart className={classes.dropdownIcons} /> My Cart
                    </Link>,
                  ]}
                />
              </ListItem>
            ) : (
              ""
            )}
            <ListItem className={classes.listItem}>
              <CustomDropdown
                noLiPadding
                navDropdown
                hoverColor={dropdownHoverColor}
                buttonText="Section"
                buttonProps={{
                  className: classes.navLink,
                  color: "transparent",
                }}
                buttonIcon={Apps}
                dropdownList={[
                  <Link
                    key="pro"
                    to="/edit-profile"
                    className={classes.dropdownLink}
                  >
                    <FaUserEdit className={classes.dropdownIcons} /> Edit
                    Profile
                  </Link>,
                  // <Link
                  //   key="pas"
                  //   to="/edit-password"
                  //   className={classes.dropdownLink}
                  // >
                  //   <FaExpeditedssl className={classes.dropdownIcons} />{" "}
                  //   Generate Password
                  // </Link>,
                  <Link to="/add-address" className={classes.dropdownLink}>
                    <FaAddressBook className={classes.dropdownIcons} /> Add
                    Address
                  </Link>,
                  <Link
                    to="/edit-update-address"
                    className={classes.dropdownLink}
                  >
                    <FaAddressBook className={classes.dropdownIcons} /> Update
                    Address
                  </Link>,
                  // <Link to="/todays-deal" className={classes.dropdownLink}>
                  //   <ShoppingBasketIcon className={classes.dropdownIcons} />
                  //   Todays Deals
                  // </Link>,
                  <Link
                    key="ad"
                    to="/my-order"
                    className={classes.dropdownLink}
                  >
                    <RiShoppingBagLine className={classes.dropdownIcons} /> My
                    Order
                  </Link>,
                ]}
              />
            </ListItem>
          </span>
        )}
      </List>
      <List className={classes.list + " " + classes.mlAuto}>
        <ListItem className={classes.listItem}>
          {!activeSession ? (
            <Button
              color="transparent"
              simple
              size="lg"
              onClick={() => setLoginModal(true)}
              // href="https://client.inshabangles.com/"
              className={classes.navLink + " " + classes.navLinkJustIcon}
            >
              <IoFingerPrintSharp className={classes.dropdownIcons} /> Log In
            </Button>
          ) : (
            <Button
              color="transparent"
              simple
              size="lg"
              onClick={handleLogOut}
              className={classes.navLink + " " + classes.navLinkJustIcon}
            >
              <IoMdLogOut className={classes.dropdownIcons} /> Log Out
            </Button>
          )}
        </ListItem>
      </List>
      <div>
        <Dialog
          classes={{
            root: classPop.modalRoot,
            paper: classPop.modal + " " + classPop.modalLogin,
          }}
          open={loginModal}
          TransitionComponent={Transition}
          keepMounted
          // onClose={() => setLoginModal(false)}
          aria-labelledby="login-modal-slide-title"
          aria-describedby="login-modal-slide-description"
        >
          <Card plain className={classPop.modalLoginCard}>
            <DialogTitle
              id="login-modal-slide-title"
              disableTypography
              className={classPop.modalHeader}
            >
              <CardHeader
                plain
                color="rose"
                className={classPop.textCenter + " " + classPop.cardLoginHeader}
              >
                <Button
                  simple
                  className={classPop.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  onClick={() => {
                    setLoginModal(false);
                    window.location.reload(false);
                  }}
                >
                  {" "}
                  <Close className={classPop.modalClose} />
                </Button>
                <h5 className={classPop.cardTitleWhite}>Log in</h5>
              </CardHeader>
            </DialogTitle>
            <DialogContent
              id="login-modal-slide-description"
              className={classPop.modalBody}
            >
              {!otpScreen ? (
                <form>
                  <p
                    className={classPop.description + " " + classPop.textCenter}
                  >
                    Welcome
                  </p>
                  <CardBody className={classPop.cardLoginBody}>
                    <GridContainer justify="center">
                      {/* <GridItem xs={12} sm={12} md={12}> */}
                      <NavPills
                        color="rose"
                        alignCenter
                        tabs={[
                          {
                            tabButton: "Mobile",
                            tabIcon: PhoneAndroidIcon,
                            tabContent: (
                              <>
                                {/* <CardBody> */}
                                <GridContainer>
                                  <GridItem xs={12} sm={12} md={12}>
                                    <PhoneInput
                                      inputStyle={{
                                        width: "100%",
                                        border: "none",
                                        borderBottom: "1px solid #040000 ",
                                        borderRadius: "0",
                                      }}
                                      country={"in"}
                                      name="mobile"
                                      value={mobile}
                                      onChange={(mobile) => {
                                        handleMobileNumber(mobile);
                                      }}
                                      required={true}
                                    />
                                    <span style={{ color: "red" }}>
                                      {mobileErr}
                                    </span>
                                  </GridItem>
                                  <Button
                                    color="rose"
                                    simple
                                    // size="lg"
                                    block
                                    onClick={handleSendOtpWithMobile}
                                  >
                                    Login
                                  </Button>
                                </GridContainer>
                                {/* </CardBody> */}
                                {/* <CardFooter
                                  className={classe.justifyContentCenter}
                                >
                                  <Button
                                    color="rose"
                                    simple
                                    // size="lg"
                                    block
                                    onClick={handleSendOtpWithMobile}
                                  >
                                    Login
                                  </Button>
                                </CardFooter> */}
                              </>
                            ),
                          },
                          {
                            tabButton: "Email",
                            tabIcon: EmailIcon,
                            tabContent: (
                              <>
                                {/* <CardBody> */}
                                <GridContainer>
                                  <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                      formControlProps={{
                                        fullWidth: true,
                                        className:
                                          classes.customFormControlClasses,
                                      }}
                                      inputProps={{
                                        onChange: (e) => handleEmail(e),
                                        id: "email",
                                        value: email,
                                        name: "email",
                                        placeholder: "Email...",
                                      }}
                                      required={true}
                                    />
                                    <span style={{ color: "red" }}>
                                      {emailErr}
                                    </span>
                                  </GridItem>
                                  <Button
                                    color="rose"
                                    simple
                                    // size="lg"
                                    block
                                    onClick={handleSendOtpWithEmail}
                                  >
                                    Login
                                  </Button>
                                </GridContainer>
                                {/* </CardBody> */}
                                {/* <CardFooter
                                  className={classe.justifyContentCenter}
                                >
                                  <Button
                                    color="rose"
                                    simple
                                    // size="lg"
                                    block
                                    onClick={handleSendOtpWithEmail}
                                  >
                                    Login
                                  </Button>
                                </CardFooter> */}
                              </>
                            ),
                          },
                        ]}
                      />
                      {/* </GridItem> */}
                    </GridContainer>
                  </CardBody>
                  <br />
                  <DialogActions
                    className={
                      classPop.modalFooter + " " + classPop.justifyContentCenter
                    }
                  >
                    <Button
                      color="rose"
                      simple
                      // size="lg"
                      href="/insha-register"
                    >
                      Create New Account
                    </Button>
                  </DialogActions>
                </form>
              ) : (
                <form>
                  <p
                    className={classPop.description + " " + classPop.textCenter}
                    style={{ marginLeft: "10px", marginRight: "10px" }}
                  >
                    6 digit OTP SMS sent on your {mobile ? "Mobile" : "Email"}{" "}
                    {otp} {reSend}
                  </p>
                  <CardBody className={classPop.cardLoginBody}>
                    <CustomInput
                      id="otpNumber"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        name: "otpNumber",
                        value: otpNumber,
                        onChange: (e) => handleOtp(e),
                        placeholder: "Enter OTP here",
                        type: "text",
                        // endAdornment: (
                        //   <InputAdornment position="end">
                        //     <Icon className={classes.inputIconsColor}>
                        //       lock_utline
                        //     </Icon>
                        //   </InputAdornment>
                        // ),
                        autoComplete: "off",
                      }}
                      required={true}
                    />
                    <p
                      className={classes.description + " " + classes.textCenter}
                      style={{ color: "red" }}
                    >
                      {otpNumberErr}
                    </p>
                  </CardBody>
                  <div className={classes.textCenter}>
                    <Button
                      simple
                      color="rose"
                      // size="lg"
                      onClick={
                        mobile ? handleLoginWithMobile : handleLoginWithEmail
                      }
                    >
                      Log in
                    </Button>
                    <Button
                      simple
                      color="rose"
                    // size="lg"
                    // onClick={handleCall}
                    >
                      <FiPhoneCall /> Call Me
                    </Button>
                  </div>

                  <p className={classes.description + " " + classes.textCenter}>
                    {" "}
                    Didn{"'"}t receive the OTP yet?{" "}
                    <span style={{ marginLeft: "10px" }}>
                      <a
                        // href="#"
                        onClick={
                          mobile
                            ? handleSendOtpWithMobile
                            : handleSendOtpWithEmail
                        }
                      >
                        Resend OTP
                      </a>
                    </span>
                  </p>
                </form>
              )}
            </DialogContent>
          </Card>
        </Dialog>
      </div>
    </div>
  );
}

InshaHeaderLink.defaultProps = {
  hoverColor: "primary",
};

InshaHeaderLink.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
};
