/*eslint-disable*/
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { localApi, credentials } from "utils/headApi";
// import axios from "axios";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import classNames from "classnames";
import Parallax from "components/Parallax/Parallax.js";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// import Slide from "@material-ui/core/Slide";
// import Tooltip from "@material-ui/core/Tooltip";
// import Close from "@material-ui/icons/Close";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Tables.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Radio from "@material-ui/core/Radio";
import SweetAlert from "react-bootstrap-sweetalert";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import InshaHeader from "views/InshaBanglesPage/Section/InshaHeader";
import InshaHeaderLink from "views/InshaBanglesPage/Section/InshaHeaderLink";
import Footer from "views/InshaBanglesPage/Section/InshaFooter";

import invoiceStyle from "assets/jss/material-kit-pro-react/views/invoiceTableStyle";
import listCart from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";
import style from "assets/jss/material-kit-pro-react/components/buttonStyle.js";
import basicsCheck from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";

import Razor from "assets/img/razorpay.png";
import PayTM from "assets/img/paytm.png";
import ICICI from "assets/img/icici.svg";

const useStyles = makeStyles(profilePageStyle);
const useStylesTable = makeStyles(invoiceStyle);
const useStyleCart = makeStyles(listCart);
const useStyleAlert = makeStyles(style);
const useStyl = makeStyles(basicsCheck);
const useStyle = makeStyles(aboutUsStyle);

export default function OredrView({ ...rest }) {
  const [addCart, setAddCart] = React.useState([]);
  const [amountRecieved, setAmountRecieved] = React.useState("");
  const [amountRecievedErr, setAmountRecievedErr] = React.useState("");
  const [bal, setBal] = React.useState("");
  const [mainAmount, setMainAmount] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [selectedEnabled, setSelectedEnabled] = React.useState("");
  const [submitPayment, setSubmitPayment] = React.useState(false);
  const [alertMsg, alertMsgOps] = React.useState("");
  const [alertType, alertTypeOps] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [billAmountErr, setBillAmountErr] = React.useState("");

  const classes = useStyles();
  const history = useHistory();
  const classeTitel = useStyle();

  const classesTable = useStylesTable();
  const classeCart = useStyleCart();
  const classeAlert = useStyleAlert();
  const classe = useStyl();

  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const client = new URLSearchParams(search).get("client");

  const activeSession = sessionStorage.getItem("sessionData");
  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getProd() {
    const response = await fetch(
      localApi +
      `/clientGroup/list/byAdminAndClientId/${userObj.admin.id}/${client}`, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    }
    );
    const json = await response.json();
    // console.log(json);
    setFirstName(json.firstname);
    setLastName(json.lastname);
    setEmail(json.email);
    setNumber(json.mobile);
    setCompany(json.company);
  }

  useEffect(() => {
    getProd();
  }, []);

  async function getList() {
    const getPay = await fetch(
      localApi +
      `/payment/byOrdersAndClientId/${id}/${client}`, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    }
    );
    const res = await getPay.json();
    // console.log(res);

    setAddCart(res);

    const getOrder = await fetch(
      localApi + `/orders/list/byAdminAndOrdersId/${userObj.admin.id}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    }
    );
    const result = await getOrder.json();

    // let r = 0;
    // console.log(result);

    setMainAmount(result);
    setAmountRecieved(
      res && res
        ? Math.round(
          result.amount -
          res.reduce((a, b) => a + b.received, 0) -
          amountRecieved
        )
        : Math.round(result.amount)
    );
    setBal(
      res && res
        ? Math.round(
          result.amount -
          res.reduce((a, b) => a + b.received, 0) -
          amountRecieved
        )
        : Math.round(result.amount)
    );
    // console.log(result);
  }

  useEffect(() => {
    getList();
  }, []);


  const handleRazorSubmit = () => {
    const getRecieved =
      addCart && addCart ? addCart.reduce((a, b) => a + b.received, 0) : 0;
    const payStatus = Math.round(
      ((getRecieved + parseInt(amountRecieved)) / mainAmount.amount) * 100
    );

    // const requestOptions = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //     Authorization: `Basic ${credentials}`,
    //   },
    //   body: JSON.stringify({
    //     ordersId: id,
    //     clientId: client,
    //     name: firstName + " " + lastName,
    //     amount: mainAmount.amount,
    //     received: Math.floor(amountRecieved),
    //   }),
    // };

    // fetch(localApi + `/payment/add`, requestOptions)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     // console.log(data);
    //     if(data.message === "Data Inserted Successfully !!!"){
    //       window.location.reload(false);
    //     }else if(data.message === "Bill Amount High"){
    //       setBillAmountErr("Bill Amount is High")
    //     }
    //   });


    let errorCount = 0;
    if (amountRecieved === "" || !amountRecieved) {
      errorCount++;
      setAmountRecievedErr("Please Enter Amount");
    }
    if (amountRecieved > bal) {
      errorCount++;
      setAmountRecievedErr("Filled Amount is High compared to Balance Amount");
    }
    if (errorCount === 0) {
      var options = {
        key: "rzp_live_5F7m24mWKBUjkA",
        amount: amountRecieved * 100,
        currency: "INR",
        name: "Sharif H M",
        description: "Insha Bangles",
        handler: function (response) {
          // alert(response.razorpay_payment_id);
          if (response.razorpay_payment_id) {
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Basic ${credentials}`,
              },
              body: JSON.stringify({
                ordersId: id,
                clientId: client,
                name: firstName + " " + lastName,
                amount: mainAmount.amount,
                received: Math.floor(amountRecieved),
              }),
            };

            fetch(localApi + "/payment/add", requestOptions)
              .then((response) => response.json())
              .then((data) => {
                console.log(data);
                // if (data.message === "Data Inserted Successfully !!! ") {
                //   const requestOptions = {
                //     method: "PUT",
                //     headers: {
                //       "Content-Type": "application/json",
                //       Accept: "application/json",
                //     },
                //     body: JSON.stringify({
                //       id: id,
                //       clientid: userObj.id,
                //       paymentstatus: payStatus,
                //       status: mainAmount.status,
                //       deliverystatus: mainAmount.deliverystatus,
                //     }),
                //   };

                //   fetch(headApi + "/order/status", requestOptions)
                //     .then((response) => response.json())
                //     .then((res) => {
                //       // console.log(res);
                //     });
                // }
              });
            setSubmitPayment(true);
            alertTypeOps("success");
            alertMsgOps(
              `₹ ${amountRecieved + "/- "
              } Congratulation your Transaction is Successfully`
            );
          }
        },
        prefill: {
          name: firstName + "" + lastName,
          email: email,
          contact: number,
        },
        notes: {
          address: "Razorpay Corporate office",
        },
        theme: {
          color: "#528FF0",
        },
      };
      var pay = new window.Razorpay(options);
      pay.open();
    }
  };

  const handlePayTMSubmit = () => { };

  const handleICICISubmit = () => {
    const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
    const requestOptions = {
      method: "POST",
      mode: 'no-cors',
      credentials: 'same-origin',
      headers: {
        "Content-Type": "application/json",
        "x-api-version": "2022-01-01",
        "x-client-id": "121710c83cc0b011e6185b5aa2017121",
        "x-client-secret": "a883833d6aea5be5395e9c1241e162ef9c0a4e2c",
        "access-control-allow-origin": "*",
      },
      body: JSON.stringify({
        order_id: "786",
        order_amount: 10.12,
        order_currency: "INR",
        customer_details: {
          customer_id: "12345",
          customer_email: "chandan@castler.com",
          customer_phone: "7903567473",
        },
      }),
    };

    fetch("https://sandbox.cashfree.com/pg/orders", requestOptions)
      .then((response) => response.json())
      .then((data) => console.log(data));
  };

  const hideAlertPayment = () => {
    setSubmitPayment(false);
    window.location.reload(false);
  };

  return (
    <div>
      <Helmet>
        <title>Payment Recieved</title>
      </Helmet>
      <InshaHeader
        brand="Home"
        links={<InshaHeaderLink dropdownHoverColor="dark" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
      />
      <Parallax
        image={require("assets/img/bg6.jpg").default}
        filter="dark"
        className={classes.parallax}
      >
        <div className={classeTitel.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classeTitel.mlAuto,
                classeTitel.mrAuto,
                classeTitel.textCenter
              )}
            >
              <h1 className={classeTitel.title}>PAYMENT DETAILS</h1>
              <h4></h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      {addCart && addCart.length !== 0 ? (
        <div className={classNames(classeCart.main, classeCart.mainRaised)}>
          <div className={classeCart.container}>
            <Card plain>
              <CardBody plain>
                <h3 className={classeCart.cardTitle}>Payment Details</h3><a href="/my-order">Back My-Order</a>

                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.profile}>
                      <h4 className={classes.title}>
                        {company} <br />
                        Order No.: {id}
                      </h4>
                    </div>
                  </GridItem>
                </GridContainer>
                <Table
                  tableHead={[
                    <span className={classesTable.tableHead}>
                      Sr.
                      {/* <br /> */}
                      No.
                    </span>,
                    <span className={classesTable.tableHead}>Name</span>,
                    <span
                      className={
                        classesTable.headRight + " " + classesTable.tableHead
                      }
                    >
                      Amount
                    </span>,
                    <span
                      className={
                        classesTable.headRight + " " + classesTable.tableHead
                      }
                    >
                      Received
                    </span>,
                    <span
                      className={
                        classesTable.headRight + " " + classesTable.tableHead
                      }
                    >
                      Balance
                    </span>,
                    // <span className={classesTable.tableHead + " " + classesTable.headRight}>""</span>,
                    // ""
                    // "Remove"
                  ]}
                  tableData={addCart.map((c, index) => [
                    [
                      <span className={classesTable.listNumber}>
                        {index + 1}
                      </span>,
                      <div>
                        <span className={classesTable.listNumber}>
                          {c.name}
                        </span>
                      </div>,
                      <div>
                        <span
                          className={
                            classesTable.headRight +
                            " " +
                            classesTable.listNumber
                          }
                        >
                          ₹{c.balAmount + "/-"}
                        </span>
                      </div>,
                      <span
                        className={
                          classesTable.headRight + " " + classesTable.listNumber
                        }
                      >
                        ₹{c.received + "/-"}
                      </span>,
                      <span
                        className={
                          classesTable.headRight + " " + classesTable.listNumber
                        }
                      >
                        ₹{c.balance + "/-"}
                      </span>,
                    ],
                  ])}
                />
                <hr />
              </CardBody>
            </Card>
          </div>
        </div>
      ) : (
        ""
      )}
      {bal == "0" ? (
        ""
      ) : (
        <div
          className={classNames(classeCart.main, classeCart.mainRaised)}
          style={{ marginTop: "5%" }}
        >
          <div className={classeCart.container}>
            {submitPayment ? (
              <SweetAlert
                type={alertType}
                style={{ display: "block", marginTop: "100px" }}
                title={alertMsg}
                onConfirm={() => hideAlertPayment()}
                confirmBtnCssClass={
                  classeAlert.button + " " + classeAlert.success
                }
              />
            ) : null}
            <Card plain>
              <CardBody plain>
                <GridContainer justify="center" alignItems="center">
                  <GridItem md={4} sm={4}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedEnabled === "a"}
                          onChange={() => setSelectedEnabled("a")}
                          value="a"
                          name="radio button enabled"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classe.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classe.radioChecked}
                            />
                          }
                          classes={{
                            checked: classe.radio,
                            root: classe.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classe.label,
                        root: classe.labelRoot,
                      }}
                      label={<img src={Razor} />}
                    />
                  </GridItem>
                  <GridItem md={4} sm={4}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedEnabled === "b"}
                          onChange={() => setSelectedEnabled("b")}
                          value="b"
                          name="radio button enabled"
                          aria-label="B"
                          icon={
                            <FiberManualRecord
                              className={classe.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classe.radioChecked}
                            />
                          }
                          classes={{
                            checked: classe.radio,
                            root: classe.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classe.label,
                        root: classe.labelRoot,
                      }}
                      label={<img src={PayTM} />}
                    />
                  </GridItem>
                  <GridItem md={4} sm={4}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedEnabled === "c"}
                          onChange={() => setSelectedEnabled("c")}
                          value="c"
                          name="radio button enabled"
                          aria-label="C"
                          icon={
                            <FiberManualRecord
                              className={classe.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classe.radioChecked}
                            />
                          }
                          classes={{
                            checked: classe.radio,
                            root: classe.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classe.label,
                        root: classe.labelRoot,
                      }}
                      label={<img src={ICICI} width={160} />}
                    />
                  </GridItem>
                </GridContainer>
                {selectedEnabled === "a" ? (
                  <>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.profile}>
                          <h4 className={classes.title}>
                            Payment <br /> {company + ", "} Order No.: {id}
                          </h4>
                        </div>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem sm={6} md={6}>
                        <CustomInput
                          labelText="Amount Recieved"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            placeholder: "Amount Recieved...",
                            type: "number",
                            onChange: (e) => {
                              setAmountRecieved(e.target.value);
                              setAmountRecievedErr("");
                            },
                            name: "amountRecieved",
                            value: amountRecieved,
                          }}
                          id="amountRecieved"
                          required={true}
                        />
                        <span style={{ color: "red" }}>
                          {amountRecievedErr}
                        </span>
                      </GridItem>
                      <GridItem sm={6} md={6}>
                        <CustomInput
                          labelText="Email"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            placeholder: "Email...",
                            onChange: (e) => {
                              setEmail(e.target.value);
                            },
                            name: "email",
                            value: email,
                            disabled: true,
                          }}
                          id="email"
                        />
                      </GridItem>
                      <GridItem sm={6} md={6}>
                        <CustomInput
                          labelText="FirstName"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            placeholder: "FirstName...",
                            onChange: (e) => {
                              setFirstName(e.target.value);
                            },
                            name: "firstName",
                            value: firstName,
                            disabled: true,
                          }}
                          id="firstName"
                        />
                      </GridItem>
                      <GridItem sm={6} md={6}>
                        <CustomInput
                          labelText="LastName"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            placeholder: "LastName...",
                            onChange: (e) => {
                              setLastName(e.target.value);
                            },
                            name: "lastName",
                            value: lastName,
                            disabled: true,
                          }}
                          id="lastName"
                        />
                      </GridItem>
                      <GridItem sm={6} md={6}>
                        <CustomInput
                          labelText="Number"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            placeholder: "Number...",
                            onChange: (e) => {
                              setNumber(e.target.value);
                            },
                            name: "number",
                            value: number,
                            disabled: true,
                          }}
                          id="number"
                        />
                      </GridItem>
                      <GridItem md={6} sm={6}></GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <Button color="rose" round onClick={handleRazorSubmit}>
                        Pay Now
                      </Button>
                    </GridContainer>
                  </>
                ) : selectedEnabled === "b" ? (
                  <>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.profile}>
                          <h4 className={classes.title}>
                            Payment <br /> Order No.: {id}
                          </h4>
                        </div>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem sm={6} md={6}>
                        <CustomInput
                          labelText="Amount Recieved"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            placeholder: "Amount Recieved...",
                            type: "number",
                            onChange: (e) => {
                              setAmountRecieved(e.target.value);
                              setAmountRecievedErr("");
                            },
                            name: "amountRecieved",
                            value: amountRecieved,
                          }}
                          id="amountRecieved"
                          required={true}
                        />
                        <span style={{ color: "red" }}>
                          {amountRecievedErr}
                        </span>
                      </GridItem>
                      <GridItem sm={6} md={6}>
                        <CustomInput
                          labelText="Email"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            placeholder: "Email...",
                            onChange: (e) => {
                              setEmail(e.target.value);
                            },
                            name: "email",
                            value: email,
                            disabled: true,
                          }}
                          id="email"
                        />
                      </GridItem>
                      <GridItem sm={6} md={6}>
                        <CustomInput
                          labelText="FirstName"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            placeholder: "FirstName...",
                            onChange: (e) => {
                              setFirstName(e.target.value);
                            },
                            name: "firstName",
                            value: firstName,
                            disabled: true,
                          }}
                          id="firstName"
                        />
                      </GridItem>
                      <GridItem sm={6} md={6}>
                        <CustomInput
                          labelText="LastName"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            placeholder: "LastName...",
                            onChange: (e) => {
                              setLastName(e.target.value);
                            },
                            name: "lastName",
                            value: lastName,
                            disabled: true,
                          }}
                          id="lastName"
                        />
                      </GridItem>
                      <GridItem sm={6} md={6}>
                        <CustomInput
                          labelText="Number"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            placeholder: "Number...",
                            onChange: (e) => {
                              setNumber(e.target.value);
                            },
                            name: "number",
                            value: number,
                            disabled: true,
                          }}
                          id="number"
                        />
                      </GridItem>
                      <GridItem md={6} sm={6}></GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <Button color="rose" round onClick={handlePayTMSubmit}>
                        Pay Now
                      </Button>
                    </GridContainer>
                  </>
                ) : selectedEnabled === "c" ? (
                  <>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.profile}>
                          <h4 className={classes.title}>
                            Payment <br /> Order No.: {id}
                          </h4>
                        </div>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem sm={6} md={6}>
                        <CustomInput
                          labelText="Amount Recieved"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            placeholder: "Amount Recieved...",
                            type: "number",
                            onChange: (e) => {
                              setAmountRecieved(e.target.value);
                              setAmountRecievedErr("");
                            },
                            name: "amountRecieved",
                            value: amountRecieved,
                          }}
                          id="amountRecieved"
                          required={true}
                        />
                        <span style={{ color: "red" }}>
                          {amountRecievedErr}
                        </span>
                      </GridItem>
                      <GridItem sm={6} md={6}>
                        <CustomInput
                          labelText="Email"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            placeholder: "Email...",
                            onChange: (e) => {
                              setEmail(e.target.value);
                            },
                            name: "email",
                            value: email,
                            disabled: true,
                          }}
                          id="email"
                        />
                      </GridItem>
                      <GridItem sm={6} md={6}>
                        <CustomInput
                          labelText="FirstName"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            placeholder: "FirstName...",
                            onChange: (e) => {
                              setFirstName(e.target.value);
                            },
                            name: "firstName",
                            value: firstName,
                            disabled: true,
                          }}
                          id="firstName"
                        />
                      </GridItem>
                      <GridItem sm={6} md={6}>
                        <CustomInput
                          labelText="LastName"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            placeholder: "LastName...",
                            onChange: (e) => {
                              setLastName(e.target.value);
                            },
                            name: "lastName",
                            value: lastName,
                            disabled: true,
                          }}
                          id="lastName"
                        />
                      </GridItem>
                      <GridItem sm={6} md={6}>
                        <CustomInput
                          labelText="Number"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            placeholder: "Number...",
                            onChange: (e) => {
                              setNumber(e.target.value);
                            },
                            name: "number",
                            value: number,
                            disabled: true,
                          }}
                          id="number"
                        />
                      </GridItem>
                      <GridItem md={6} sm={6}></GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <Button color="rose" round onClick={handleICICISubmit}>
                        Pay Now
                      </Button>
                    </GridContainer>
                  </>
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}
